import React from 'react';
import { Button, Form, Input, Col, Row, Space, Typography, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { SaveOutlined } from '@ant-design/icons';
import Page from '../../common/Page';
import config from '../../../config';
import { useProjectAdd } from './useProjectAdd';
import { ProjectType } from './project.type';

const { Title } = Typography;

const AddProject: React.FC = () => {
  const { t } = useTranslation('common');
  const { createProject, loading } = useProjectAdd();

  const typeOptions = [
    {
      value: ProjectType.EXTERNAL,
      label: t('project.extern'),
    },
    {
      value: ProjectType.INTERNAL,
      label: t('project.intern'),
    },
  ];

  return (
    <Page>
      <Row>
        <Col>
          <Row align='middle' justify='center'>
            <Col>
              <Title level={2}>{t('project.addTitle')}</Title>
            </Col>
          </Row>
          <Form
            name='project-form'
            className='project-form'
            initialValues={{ remember: false }}
            onFinish={createProject}
            layout='vertical'
          >
            <Form.Item
              name='name'
              label={t('project.nameLabel')}
              rules={[{ required: true, message: t('project.nameError') ?? '' }]}
            >
              <Input type='text' placeholder={t('project.namePlaceholder') ?? ''} />
            </Form.Item>
            <Form.Item
              name='code'
              label={t('project.codeLabel')}
              rules={[{ required: true, message: t('project.codeError') ?? '' }]}
            >
              <Input type='text' placeholder={t('project.codePlaceholder') ?? ''} />
            </Form.Item>
            <Form.Item
              name='type'
              label={t('project.listTypePlaceholder')}
              rules={[{ required: true, message: t('project.typeError') ?? '' }]}
            >
              <Select placeholder={t('project.listTypePlaceholder') ?? ''} options={typeOptions} />
            </Form.Item>
            <Form.Item>
              <Space size='small'>
                <Button
                  type='primary'
                  htmlType='submit'
                  icon={<SaveOutlined />}
                  loading={loading}
                  disabled={loading}
                >
                  {t('common.saveBtn') ?? ''}
                </Button>
                <Link to={config.routes.projects}>{t('common.back') ?? ''}</Link>
              </Space>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Page>
  );
};

export default AddProject;
