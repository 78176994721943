import { useLocation, Navigate, Outlet } from 'react-router-dom';
import React from 'react';
import { useUser } from './pages/users/UserContext';

const Auth = ({ allowedRoles, redirect }: any): React.ReactElement | null => {
  const { roleData } = useUser();
  const location = useLocation();

  return allowedRoles.find((role: string) => roleData.role === role) != null ? (
    <Outlet />
  ) : (
    <Navigate to={redirect} state={{ from: location }} replace />
  );
};

export default Auth;
