import { useNavigate } from 'react-router-dom';
import React, { useMemo } from 'react';
import {
  PaperClipOutlined,
  UserOutlined,
  ProjectOutlined,
  ScheduleOutlined,
  DashboardOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';

import { useUser } from '../pages/users/UserContext';
import { UserRole } from '../pages/users/user.role';

type MenuItem = Required<MenuProps>['items'][number];
interface MenuItemConfig {
  title: string;
  route: string;
  component: React.ReactNode;
  forAdminOnly: boolean;
}

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return { key, icon, children, label };
}

const itemsConfig: MenuItemConfig[] = [
  { title: 'Dashboard', route: '/dashboard', component: <DashboardOutlined />, forAdminOnly: true },
  { title: 'Invoices', route: '/invoices', component: <ScheduleOutlined />, forAdminOnly: false },
  {
    title: 'Timesheet',
    route: '/timesheet',
    component: <PaperClipOutlined />,
    forAdminOnly: false,
  },
  { title: 'Projects', route: '/projects', component: <ProjectOutlined />, forAdminOnly: true },
  { title: 'Users', route: '/users', component: <UserOutlined />, forAdminOnly: true },
];

const MainMenu: React.FC = () => {
  const navigate = useNavigate();
  const { roleData } = useUser();

  const items = useMemo(() => {
    return itemsConfig
      .filter((item: MenuItemConfig) =>
        item.forAdminOnly ? roleData.role === UserRole.ADMIN : true,
      )
      .map((item: MenuItemConfig) => getItem(item.title, item.route, item.component));
  }, [roleData.role]);

  return (
    <Menu
      theme='dark'
      defaultSelectedKeys={[window.location.pathname]}
      mode='inline'
      items={items}
      style={{
        padding: 10,
      }}
      onClick={({ key }) => {
        navigate(key);
      }}
    />
  );
};

export default MainMenu;
