import React, { ReactElement, ReactNode, useContext, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useFetch from 'use-http';

import { useUser } from '../../users/UserContext';

import { UserRole } from '../../users/user.role';

import { initialFilterValsInvoice, initialPagValue } from '../../../../utils/validationSchemas';
import {
  FetchedInvoiceType,
  FilterOptionsInvoice,
  PaginationOptions,
  ResponseMetaType,
} from '../invoice-types';

import config from '../../../../config';

interface InvoiceListingType {
  filterOptions: FilterOptionsInvoice;
  setFilterOptions: (filters: FilterOptionsInvoice) => void;
  pagination: PaginationOptions;
  setPagination: (pagOptions: PaginationOptions) => void;
  data: { data: FetchedInvoiceType[]; meta: ResponseMetaType; links: Record<string, string> };
  loading: boolean;
  error: {} | undefined;
  getInvoices: () => void;
  url: string;
}

const defaultValue: InvoiceListingType = {
  filterOptions: { from: undefined, to: undefined, status: undefined, userEmail: undefined },
  setFilterOptions: () => {},
  pagination: { page: 0, pageSize: 0 },
  setPagination: () => {},
  data: {
    data: [
      {
        amount: '',
        createdAt: '',
        fileName: '',
        id: 0,
        status: 0,
        user: {},
        userId: 0,
      },
    ],
    meta: { currentPage: 0, itemsPerPage: 0, totalItems: 0, totalPages: 0, sortBy: [] },
    links: {},
  },
  loading: false,
  error: undefined,
  getInvoices: () => {},
  url: '',
};

export const InvoiceListingContext = React.createContext<InvoiceListingType>(defaultValue);

export const InvoiceListingProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const { roleData } = useUser();
  const role = roleData.role;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [filterOptions, setFilterOptions] = useState<FilterOptionsInvoice>(() => {
    const fromStorage = sessionStorage.getItem('invoice');
    if (fromStorage !== null) {
      const storageParams = new URLSearchParams(fromStorage);
      return initialFilterValsInvoice(storageParams);
    } else {
      return initialFilterValsInvoice(queryParams);
    }
  });

  const [pagination, setPagination] = useState<PaginationOptions>(() =>
    initialPagValue(queryParams),
  );

  const { from, to, status, userEmail: email } = filterOptions;
  const { page, pageSize } = pagination;

  const url = useMemo(() => {
    const fromISO =
      typeof from === 'undefined' ? from : from?.subtract(1, 'day').endOf('day').toISOString();
    const toISO = typeof to === 'undefined' ? to : to?.add(1, 'day').startOf('day').toISOString();

    let path;

    if (role === UserRole.ADMIN) {
      path = `${config.api.baseUrl}${config.api.invoicesPath}?`;
    } else if (role === UserRole.COLLABORATOR) {
      path = `${config.api.baseUrl}${config.api.invoicesMe}?`;
    } else {
      path = '';
    }

    if (typeof email !== 'undefined') {
      path += `filter.user.email=$eq:${email}&`;
    }

    if (typeof fromISO !== 'undefined' && typeof toISO !== 'undefined') {
      path += `filter.createdAt=$gt:${fromISO}&filter.createdAt=$lt:${toISO}&`;
    }
    if (typeof fromISO !== 'undefined') {
      path += `filter.createdAt=$gt:${fromISO}&`;
    }
    if (typeof toISO !== 'undefined') {
      path += `filter.createdAt=$lt:${toISO}&`;
    }
    if (typeof status !== 'undefined') {
      path += `filter.status=$eq:${status}&`;
    }

    path += `page=${page}&limit=${pageSize}`;

    return path;
  }, [from, to, status, email, page, pageSize, role]);

  const { data, error, loading, get: getInvoices } = useFetch(url, { data: {} }, [url]);

  const values = useMemo(() => {
    return {
      filterOptions,
      setFilterOptions,
      pagination,
      setPagination,
      data,
      error,
      loading,
      getInvoices,
      url,
    };
  }, [url, filterOptions, pagination, data, error, loading, getInvoices]);

  return <InvoiceListingContext.Provider value={values}>{children}</InvoiceListingContext.Provider>;
};

export const useInvoiceListing = (): InvoiceListingType => {
  return useContext(InvoiceListingContext);
};
