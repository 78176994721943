import React, { ReactElement, ReactNode, useContext, useEffect } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import jwt from 'jwt-decode';
import dayjs from 'dayjs';
import { UserRole } from './user.role';
import { useNavigate } from 'react-router-dom';
import config from '../../../config';

interface UserType {
  roleData: { role: string };
}

const defaultValue: UserType = { roleData: { role: '' } };
export const UserContext = React.createContext<UserType>(defaultValue);

export const UserProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const [roleData] = useLocalStorage('role', { role: '' });
  const navigate = useNavigate();
  const [isOnboarded] = useLocalStorage('is_onboarded', false);
  const [token] = useLocalStorage('token', { bearer: '' });

  useEffect(() => {
    try {
      if (token.bearer !== '') {
        const decoded: Record<string, any> = jwt(token.bearer);
        const isTokenExpired = dayjs(decoded.exp * 1000).isBefore(dayjs());

        if (!isOnboarded && roleData.role === UserRole.COLLABORATOR && !isTokenExpired) {
          navigate(config.routes.profile);
        }
      }
    } catch {
      console.log('Invalid password -> UserContext');
    }
  }, [isOnboarded, navigate, roleData.role, token.bearer]);

  const value = {
    roleData,
  };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUser = (): UserType => {
  return useContext(UserContext);
};
