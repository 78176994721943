import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Button, Dropdown, Popconfirm, Space, Tag } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { DeleteOutlined, DownloadOutlined, DownOutlined } from '@ant-design/icons';
import { format } from 'date-fns';

import { useUser } from '../../users/UserContext';
import { useInvoiceListing } from '../contexts/InvoiceListingContext';

import { useInvoiceStatus } from '../../../../hooks/invoices/useInvoiceStatus';
import useInvoices from '../useInvoices';

import { addPaginationQuery } from '../../../../utils/searchParams';

import { InvoiceStatus } from '../invoice.status';
import { UserRole } from '../../users/user.role';

import { FetchedInvoiceType } from '../invoice-types';
import { formatNumber } from '../../../../utils/formatNumber';

function InvoiceDataTable(): React.ReactElement {
  const { t } = useTranslation('common');

  const { roleData } = useUser();
  const role = roleData.role;

  const statusOptions = useInvoiceStatus();

  const [, setSearchParams] = useSearchParams();
  const location = useLocation();
  const queryParams: URLSearchParams = new URLSearchParams(location.search);

  const { data, error, loading, pagination, getInvoices, setPagination } = useInvoiceListing();

  const { page, pageSize } = pagination;

  const {
    downloadInvoice,
    changeInvoiceStatus,
    deleteInvoice,
    loadingDelete,
    loadingDownload,
    loadingChangeStatus,
  } = useInvoices();

  const confirmDelete = async (id: string): Promise<void> => {
    const newPage = (data?.meta?.totalItems - 1) % data?.meta?.itemsPerPage === 0 ? page - 1 : page;
    await deleteInvoice(id);
    setPagination({ page: newPage, pageSize });
    setSearchParams(addPaginationQuery(queryParams, newPage, pageSize));
    if (newPage === page) {
      await getInvoices();
    }
  };

  const columns: ColumnsType<FetchedInvoiceType> = [
    {
      title: 'Amount (RON + VAT)',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount) => formatNumber(amount),
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_: unknown, { createdAt }: { createdAt: string }) => {
        const date = format(new Date(createdAt), 'dd-MM-yyyy');
        return <>{date}</>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: InvoiceStatus) => {
        let color = 'black';
        const text: Record<number, string> = {
          [InvoiceStatus.PENDING]: t('invoice.pending'),
          [InvoiceStatus.APPROVED]: t('invoice.approved'),
          [InvoiceStatus.PAID]: t('invoice.paid'),
          [InvoiceStatus.UNAPPROVED]: t('invoice.unapproved'),
          [InvoiceStatus.INACTIVE]: t('invoice.inactive'),
        };

        if (status === InvoiceStatus.APPROVED || status === InvoiceStatus.PAID) {
          color = 'green';
        }
        if (status === InvoiceStatus.INACTIVE || status === InvoiceStatus.UNAPPROVED) {
          color = 'red';
        }
        return (
          <Tag color={color} key={status}>
            {text[status]}
          </Tag>
        );
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_: unknown, record: FetchedInvoiceType) => {
        const { PENDING, APPROVED, PAID, UNAPPROVED, INACTIVE } = InvoiceStatus;
        const { status, id } = record;
        const filteredStatus = [PENDING, APPROVED, PAID, UNAPPROVED, INACTIVE].filter(
          (filteredStatus) => {
            return filteredStatus !== status;
          },
        );
        const items = filteredStatus.map((item) => {
          const label =
            statusOptions.filter((i) => i).find((opt) => opt.value === item)?.label ?? '';
          return {
            key: item,
            label: (
              <Button
                type='ghost'
                onClick={async () => {
                  await changeInvoiceStatus(`${id}`, item);
                  getInvoices();
                }}
              >
                {label}
              </Button>
            ),
          };
        });
        return (
          <Space size={4}>
            {role === UserRole.ADMIN ? (
              <>
                <Dropdown menu={{ items }} placement='bottom'>
                  <Button>
                    {t('invoice.changeStatus')} <DownOutlined />
                  </Button>
                </Dropdown>
              </>
            ) : (
              <></>
            )}
            <Button
              icon={<DownloadOutlined />}
              onClick={async () => await downloadInvoice(record)}
            ></Button>

            <Popconfirm
              title={t('invoice.deleteInvoice')}
              description={t('invoice.deleteInvoiceConfirmMessage')}
              okText={t('invoice.yes')}
              cancelText={t('invoice.no')}
              onConfirm={() => {
                confirmDelete(`${record.id}`);
              }}
            >
              <Button icon={<DeleteOutlined />} danger></Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  if (role === UserRole.ADMIN) {
    columns.splice(0, 0, {
      title: 'User email',
      dataIndex: 'user',
      key: 'user',
      render: (user: { email: string }) => {
        return user?.email;
      },
    });
  }

  return (
    <Table
      dataSource={error !== undefined || !Array.isArray(data?.data) ? [] : data?.data}
      columns={columns}
      loading={loading || loadingDelete || loadingDownload || loadingChangeStatus}
      bordered
      rowKey='id'
      pagination={{
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '15', '20', '25', '30'],
        current: page >= 1 ? page : 1,
        total: typeof data?.meta?.totalItems !== 'undefined' ? data?.meta?.totalItems : 0,
        position: ['bottomCenter'],
        pageSize,
        onChange: (page, pageSize) => {
          localStorage.setItem('pageSize', `${pageSize}`);
          setPagination({ page, pageSize });
          setSearchParams(addPaginationQuery(queryParams, page, pageSize));
        },
      }}
    />
  );
}

export default InvoiceDataTable;
