import { DefaultOptionType } from 'antd/es/select';
import { useMemo } from 'react';
import { useFetch } from 'use-http';

import config from '../../config';

interface UserData {
  email: string;
}

export function useEmailFilter(): { options: DefaultOptionType[]; getEmails: () => Promise<void> } {
  const {
    data = [],
    error,
    get: getEmails,
  } = useFetch(`${config.api.baseUrl}${config.api.usersGetEmailAdresses}`, {}, []);

  const options = useMemo(() => {
    return data.map((user: UserData) => {
      const PopulateEmailSelect = (userData: UserData, error: any): string => {
        return error !== undefined ? '' : `${userData?.email}`;
      };
      return {
        value: PopulateEmailSelect(user, error),
        label: PopulateEmailSelect(user, error),
      };
    });
  }, [data, error]);

  return { options, getEmails };
}
