import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { Button, Popconfirm, Row, Space, Table } from 'antd';
import { ColumnsType, TableProps } from 'antd/es/table';

import { useTimesheetListing } from '../contexts/TimesheetListingContext';
import { useUser } from '../../users/UserContext';

import useModifyTimesheets from '../../../../hooks/timesheets/useModifyTimesheets';

import { addPaginationQuery } from '../../../../utils/searchParams';

import { UserRole } from '../../users/user.role';
import { FetchedTimesheetType, ProjectDataType } from '../timesheet-types';
import config from '../../../../config';
import { formatMoney } from '../../../../utils/formatMoney';
import { SorterResult } from 'antd/es/table/interface';

function TimesheetDataTable(): React.ReactElement {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const { roleData } = useUser();
  const role = roleData.role;

  const { data, error, loading, pagination, getTimesheets, setPagination, setSort } =
    useTimesheetListing();
  const { page, pageSize } = pagination;

  const { deleteTimesheet, loadingDelete } = useModifyTimesheets();

  const [, setSearchParams] = useSearchParams();
  const location = useLocation();
  const queryParams: URLSearchParams = new URLSearchParams(location.search);

  const confirmDelete = async (id: string): Promise<void> => {
    const newPage = (data?.meta?.totalItems - 1) % data?.meta?.itemsPerPage === 0 ? page - 1 : page;
    await deleteTimesheet(id);
    setPagination({ page: newPage, pageSize });
    setSearchParams(addPaginationQuery(queryParams, newPage, pageSize));
    if (newPage === page) {
      await getTimesheets();
    }
  };

  const columns: ColumnsType<FetchedTimesheetType> = [
    {
      title: 'Period',
      dataIndex: 'from',
      key: 'from',
      render: (_: unknown, { from, to }: { from: string; to: string }) => {
        const day = from === to ? from : `${from} - ${to}`;
        return <>{day}</>;
      },
    },
    {
      title: 'Hours',
      dataIndex: 'hours',
      key: 'hours',
      render: (_: unknown, { hours, description }: { hours: number; description: string }) => {
        return (
          <div>
            <div>{hours}</div>
            <small>{description}</small>
          </div>
        );
      },
      sorter: true,
      onHeaderCell: () => ({
        onClick: () => {
          setPagination({ page: 1, pageSize });
        },
      }),
    },
    {
      title: 'Project',
      dataIndex: 'project',
      key: 'project',
      render: (project: ProjectDataType) => project?.name,
    },
    {
      title: 'Actions',
      render: ({ id }: { id: number }) => {
        return (
          <Row>
            <Space size={4}>
              <Button
                icon={<EditOutlined />}
                onClick={() => {
                  navigate(config.routes.timesheetEdit.replace(':id', `${id}`));
                }}
              ></Button>
              <Popconfirm
                title={t('timesheet.deleteTitle')}
                description={t('timesheet.deleteDescription')}
                okText={t('timesheet.yes')}
                cancelText={t('timesheet.no')}
                onConfirm={() => {
                  confirmDelete(`${id}`);
                }}
              >
                <Button icon={<DeleteOutlined />} danger></Button>
              </Popconfirm>
            </Space>
          </Row>
        );
      },
    },
  ];

  if (role === UserRole.ADMIN) {
    columns.unshift({
      title: 'User email',
      dataIndex: 'user',
      key: 'user',
      render: (user: { email: string }) => {
        return user?.email;
      },
    });
    columns.unshift({
      title: 'Cost',
      dataIndex: 'calculatedCost',
      key: 'calculatedCost',
      render: (calculatedCost) => formatMoney(calculatedCost),
    });
  }

  const handleTableChange: TableProps<FetchedTimesheetType>['onChange'] = (
    pagination,
    filters,
    sorter,
  ) => {
    const sortInfo = sorter as SorterResult<FetchedTimesheetType>;
    const column = sortInfo.columnKey as string | undefined;
    const order =
      sortInfo.order === 'ascend' ? 'ASC' : sortInfo.order === 'descend' ? 'DESC' : undefined;
    setSort({ column, order });
  };

  return (
    <Table
      dataSource={error != null || !Array.isArray(data?.data) ? [] : data.data}
      columns={columns}
      loading={loading || loadingDelete}
      bordered
      rowKey='id'
      onChange={handleTableChange}
      pagination={{
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '15', '20', '25', '30'],
        current: page >= 1 ? page : 1,
        total: typeof data?.meta?.totalItems !== 'undefined' ? data?.meta?.totalItems : 0,
        position: ['bottomCenter'],
        pageSize,
        onChange: (page, pageSize) => {
          localStorage.setItem('pageSize', `${pageSize}`);
          setPagination({ page, pageSize });
          setSearchParams(addPaginationQuery(queryParams, page, pageSize));
        },
      }}
    />
  );
}

export default TimesheetDataTable;
