import { Button, Col, Row, Space, Typography } from 'antd';

import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import React from 'react';

import Page from '../../common/Page';

import { useUser } from '../users/UserContext';
import { UserEmailFilterProvider } from '../../../general-purpose-contexts/UserEmailFilterContext';
import { TimesheetListingProvider } from './contexts/TimesheetListingContext';

import TimesheetFilters from './subcomponents/TimesheetFilters';
import TimesheetDataTable from './subcomponents/TimesheetDataTable';
import DownloadTimesheetsButton from './subcomponents/DownloadTimesheetsButton';
import TimesheetStatistics from './subcomponents/TimesheetStatistics';

import { UserRole } from '../users/user.role';

import config from '../../../config';

const { Title } = Typography;

const Timesheet: React.FC = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const { roleData } = useUser();

  return (
    <Page>
      <UserEmailFilterProvider>
        <TimesheetListingProvider>
          <Space direction='vertical' size='middle' className='w-100'>
            <Row>
              <Col xs={24} sm={12} md={6}>
                <Title level={3}>{t('timesheet.title')}</Title>
              </Col>
              <Col xs={24} sm={12} md={18}>
                <Row justify='end'>
                  <Button
                    icon={<PlusOutlined />}
                    type={'primary'}
                    onClick={() => {
                      navigate(config.routes.timesheetAdd);
                    }}
                  >
                    {`${t('timesheet.addTimesheet')}`}
                  </Button>
                </Row>
              </Col>
            </Row>

            <TimesheetFilters />
            <TimesheetStatistics />
            {roleData.role === UserRole.ADMIN ? <DownloadTimesheetsButton /> : <></>}
            <TimesheetDataTable />
          </Space>
        </TimesheetListingProvider>
      </UserEmailFilterProvider>
    </Page>
  );
};

export default Timesheet;
