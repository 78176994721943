import React, { useCallback, useEffect } from 'react';
import { Button, Col, Form, Input, Row, Select, Space, Typography, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useFetch } from 'use-http';
import { useForm } from 'antd/es/form/Form';

import Page from '../../common/Page';
import config from '../../../config';
import { ProjectStatus } from './project.status';
import { ProjectType } from './project.type';

const { Title } = Typography;

const { api } = config;
const { baseUrl, projectsEditPath } = api;

interface UpdateEdit {
  name: string;
  status: number;
}

const ProjectEdit: React.FC = () => {
  const { t } = useTranslation('common');
  const { id } = useParams();
  const navigate = useNavigate();

  const { get } = useFetch(`${baseUrl}`);
  const { put, error: errorSave, data, loading } = useFetch(`${baseUrl}`);
  const [form] = useForm();

  const loadData = useCallback(async () => {
    try {
      form.setFieldsValue(await get(`${projectsEditPath}`.replace(':id', `${id}`)));
    } catch (ex) {
      //
    }
  }, [form, get, id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const statusOptions = [
    {
      value: ProjectStatus.ACTIVE,
      label: t('project.active'),
    },
    {
      value: ProjectStatus.INACTIVE,
      label: t('project.inactive'),
    },
  ];

  const typeOptions = [
    {
      value: ProjectType.EXTERNAL,
      label: t('project.extern'),
    },
    {
      value: ProjectType.INTERNAL,
      label: t('project.intern'),
    },
  ];

  const onFinish = useCallback(
    async (data: UpdateEdit) => {
      await put(`${projectsEditPath}`.replace(':id', `${id}`), data);
    },
    [id, put],
  );

  useEffect(() => {
    if (!isNaN(data?.id)) {
      notification.success({
        message: t('common.success'),
        description: t('project.updatedSuccessfully'),
        placement: 'topLeft',
      });
      return navigate(config.routes.projects);
    }
    if (errorSave !== undefined) {
      notification.error({
        message: t('common.error'),
        description: t('project.updatedError'),
        placement: 'topLeft',
      });

      navigate(config.routes.projects);
    }
  }, [data, errorSave, navigate, t]);

  return (
    <Page>
      <Title>{t('project.titleEdit') ?? ''}</Title>
      <Form
        name='project-edit-form'
        className='project-form'
        initialValues={{ remember: false }}
        layout='vertical'
        form={form}
        onFinish={onFinish}
        disabled={loading}
      >
        <Row gutter={12}>
          <Col xl={6}>
            <Form.Item
              name='name'
              label={t('project.nameLabel')}
              rules={[{ required: true, message: t('project.nameError') ?? '' }]}
            >
              <Input type='text' placeholder={t('project.listNamePlaceholder') ?? ''} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xl={4}>
            <Form.Item name='status' label={t('project.listStatusPlaceholder')}>
              <Select
                placeholder={t('project.listStatusPlaceholder') ?? ''}
                options={statusOptions}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xl={4}>
            <Form.Item name='type' label={t('project.listTypePlaceholder')}>
              <Select placeholder={t('project.listTypePlaceholder') ?? ''} options={typeOptions} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Space size='small'>
            <Button
              type='primary'
              htmlType='submit'
              className='login-form-button'
              disabled={loading}
              loading={loading}
            >
              {t('common.update') ?? ''}
            </Button>
            <Link to={config.routes.projects}>{t('common.back') ?? ''}</Link>
          </Space>
        </Form.Item>
      </Form>
    </Page>
  );
};

export default ProjectEdit;
