import { useMemo } from 'react';
import useFetch from 'use-http';

import { useUser } from '../../components/pages/users/UserContext';

import {
  ColumnSortingOptions,
  FilterOptionsTimesheet,
  PaginationOptions,
  PathsType,
  TimesheetResponseDataType,
} from '../../components/pages/timesheet/timesheet-types';

import { UserRole } from '../../components/pages/users/user.role';
import config from '../../config';

interface FetchTimesheetsDataType {
  data: TimesheetResponseDataType;
  loading: boolean;
  error: {} | undefined;
  getTimesheets: () => void;
  url: string;
}

function useFetchTimesheets(
  filterOptions: FilterOptionsTimesheet,
  pagination: PaginationOptions,
  paths: PathsType,
  sort: ColumnSortingOptions,
): FetchTimesheetsDataType {
  const { roleData } = useUser();
  const role = roleData.role;

  const { from, to, project, userEmail: email } = filterOptions;
  const { page, pageSize } = pagination;
  const { column, order } = sort;

  const url = useMemo(() => {
    const fromISO =
      typeof from === 'undefined' ? from : from?.subtract(1, 'day').endOf('day').toISOString();
    const toISO = typeof to === 'undefined' ? to : to?.add(1, 'day').startOf('day').toISOString();

    let path;

    if (role === UserRole.ADMIN) {
      path = `${config.api.baseUrl}${paths?.adminCasePath}?`;
    } else if (role === UserRole.COLLABORATOR) {
      path = `${config.api.baseUrl}${paths?.collabCasePath}?`;
    } else {
      path = '';
    }

    if (typeof email !== 'undefined') {
      path += `filter.user.email=$eq:${email}&`;
    }

    if (typeof fromISO !== 'undefined' && typeof toISO !== 'undefined') {
      path += `filter.from=$gt:${fromISO}&filter.to=$lt:${toISO}&`;
    } else if (typeof fromISO !== 'undefined') {
      path += `filter.from=$gt:${fromISO}&`;
    } else if (typeof toISO !== 'undefined') {
      path += `filter.to=$lt:${toISO}&`;
    }

    if (typeof project !== 'undefined') {
      path += `filter.project_id=$eq:${project}&`;
    }

    if (typeof order !== 'undefined') {
      path += `sortBy=${column}:${order}&`;
    }
    path += `page=${page}`;

    if (typeof pageSize === 'number') {
      path += `&limit=${pageSize}`;
    }

    return path;
  }, [
    from,
    to,
    project,
    email,
    page,
    pageSize,
    column,
    order,
    role,
    paths.adminCasePath,
    paths.collabCasePath,
  ]);

  const { data, error, loading, get: getTimesheets } = useFetch(url, { data: {} }, [url]);

  return {
    data,
    error,
    loading,
    getTimesheets,
    url,
  };
}

export default useFetchTimesheets;
