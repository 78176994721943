import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Form, Input, Row, Space, Typography, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useFetch } from 'use-http';
import { useForm } from 'antd/es/form/Form';

import Page from '../../common/Page';
import config from '../../../config';
import { useUser } from '../users/UserContext';
import { UserRole } from '../users/user.role';
import { useLocalStorage } from 'usehooks-ts';

const { Title } = Typography;

const { api } = config;
const { baseUrl, usersMe } = api;

interface UpdateEdit {
  firstName: string | null;
  lastName: string | null;
  companyName: string | null;
  bankAccount: string | null;
}

const ProfileEdit: React.FC = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const { get } = useFetch(`${baseUrl}`);
  const { put, loading, response } = useFetch(`${baseUrl}`);

  const [form] = useForm();
  const { roleData } = useUser();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isOnboarded, setIsOnboarded] = useLocalStorage('is_onboarded', false);

  useEffect(() => {
    setIsAdmin(roleData.role === UserRole.ADMIN);
  }, [roleData.role]);

  useEffect(() => {
    async function loadData(): Promise<void> {
      get(usersMe)
        .then((data) => {
          form.setFieldsValue(data);
        })
        .catch((err) => console.log(err));
    }

    loadData();
  }, []);

  async function handleOnFinish(formData: UpdateEdit): Promise<void> {
    await put(usersMe, formData);
    if (response.ok) {
      if (Boolean(formData.companyName) && Boolean(formData.bankAccount) && !isOnboarded) {
        setIsOnboarded(true);
      }
      notification.success({
        message: t('common.success'),
        description: t('users.userUpdatedSuccessfully'),
        placement: 'topLeft',
      });
    }
    if (!response.ok) {
      notification.error({
        message: t('common.error'),
        description: t('users.userUpdatedError'),
        placement: 'topLeft',
      });
    }
  }

  return (
    <Page>
      <Title>{t('profile.title') ?? ''}</Title>
      {!isOnboarded && roleData.role === UserRole.COLLABORATOR && (
        <Alert
          style={{ marginBottom: '20px' }}
          message={t('profile.notOnboarded')}
          type='warning'
        />
      )}
      <Form
        name='users-form'
        className='users-form'
        initialValues={{ remember: false }}
        layout='vertical'
        form={form}
        onFinish={async (values) => await handleOnFinish(values)}
        disabled={loading}
      >
        {!isAdmin && (
          <>
            <Row gutter={12}>
              <Col xl={6}>
                <Form.Item
                  name='companyName'
                  label={t('users.companyNameLabel')}
                  rules={[{ required: true, message: t('users.companyNameError') ?? '' }]}
                >
                  <Input type='text' placeholder={t('users.companyNamePlaceholder') ?? ''} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col xl={12}>
                <Form.Item
                  name='bankAccount'
                  label={t('users.bankAccountLabel')}
                  rules={[{ required: true, message: t('users.bankAccountError') ?? '' }]}
                >
                  <Input type='text' placeholder={t('users.bankAccountPlaceholder') ?? ''} />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
        <Row gutter={12}>
          <Col xl={6}>
            <Form.Item
              name='firstName'
              label={t('users.firstNameLabel')}
              rules={[{ required: true, message: t('users.firstNameError') ?? '' }]}
            >
              <Input type='text' placeholder={t('users.userPlaceholder') ?? ''} />
            </Form.Item>
          </Col>
          <Col xl={6}>
            <Form.Item
              name='lastName'
              label={t('users.lastNameLabel')}
              rules={[{ required: true, message: t('users.lastNameError') ?? '' }]}
            >
              <Input type='text' placeholder={t('users.lastUserPlaceholder') ?? ''} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Space size='small'>
            <Button
              type='primary'
              htmlType='submit'
              className='login-form-button'
              disabled={loading}
              loading={loading}
            >
              {t('common.update') ?? ''}
            </Button>
            <Button onClick={() => navigate(-1)}>{t('common.back') ?? ''}</Button>
          </Space>
        </Form.Item>
      </Form>
    </Page>
  );
};

export default ProfileEdit;
