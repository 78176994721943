import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Button, Card, Col, DatePicker, Form, Row, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { useTimesheetListing } from '../contexts/TimesheetListingContext';
import { useUserEmailFilterContext } from '../../../../general-purpose-contexts/UserEmailFilterContext';
import { useUser } from '../../users/UserContext';
import { useProjectList } from '../../project/useProjectList';
import { displaySearchParams } from '../../../../utils/searchParams';
import { UserRole } from '../../users/user.role';
import { rangePresets } from '../../../common/RangePresets';
import { getPageSize } from '../../../common/DataTableDefaults';

const { RangePicker } = DatePicker;

function TimesheetFilters(): React.ReactElement {
  const { t } = useTranslation('common');
  const [form] = Form.useForm();

  const { roleData } = useUser();
  const { options } = useUserEmailFilterContext();
  const { projectOptions } = useProjectList();
  const { filterOptions, setFilterOptions, setPagination } = useTimesheetListing();
  const [, setSearchParams] = useSearchParams();

  const handleFilteringOptions = async (): Promise<void> => {
    const currentFilters = form.getFieldsValue(['period', 'project', 'userEmail']);
    currentFilters.from =
      currentFilters.period != null && currentFilters.period.length === 2
        ? currentFilters.period[0]
        : undefined;
    currentFilters.to =
      currentFilters.period != null && currentFilters.period.length === 2
        ? currentFilters.period[1]
        : undefined;

    setFilterOptions(currentFilters);
    setPagination({ page: 1, pageSize: getPageSize() });

    await displaySearchParams(currentFilters, setSearchParams);
    sessionStorage.setItem('timesheet', window.location.search);
  };

  useEffect(() => {
    filterOptions.period = [filterOptions.from, filterOptions.to];
    form.setFieldsValue(filterOptions);
    setSearchParams(sessionStorage.getItem('timesheet') ?? window.location.search);
    // De adaugat setSession.setItem( din query params dar fara pag si pageSize)
  }, []);

  return (
    <Card>
      <Form
        name='filter-timesheets'
        form={form}
        layout='vertical'
        wrapperCol={{ span: 20, offset: 0 }}
      >
        <Row>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <Form.Item name='period' label={t('rangePicker.label') ?? ''}>
              <RangePicker presets={rangePresets} className='w-100' />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={8} xl={6}>
            <Form.Item name={'project'} label={t('timesheet.project') ?? ''}>
              <Select
                options={projectOptions}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
              ></Select>
            </Form.Item>
          </Col>

          {roleData.role === UserRole.ADMIN ? (
            <Col xs={24} sm={24} md={24} lg={8} xl={6}>
              <Form.Item
                name='userEmail'
                label={t('invoice.userEmail') ?? ''}
                rules={[{ type: 'email' }]}
              >
                <Select style={{ width: '100%' }} allowClear showSearch options={options} />
              </Form.Item>
            </Col>
          ) : (
            <></>
          )}
        </Row>
        <Row justify='end'>
          <Col>
            <Button
              htmlType='button'
              icon={<SearchOutlined />}
              onClick={() => {
                handleFilteringOptions();
              }}
            >
              {t('common.filterTitle') ?? ''}
            </Button>
            <Button
              htmlType='reset'
              type={'text'}
              onClick={() => {
                form.resetFields();
                sessionStorage.setItem('timesheet', '');
                handleFilteringOptions();
              }}
            >
              {t('common.clearBtn') ?? ''}
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default TimesheetFilters;
