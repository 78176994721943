import { useFetch } from 'use-http';
import { useCallback } from 'react';
import { FormInstance, notification } from 'antd';
import { useTranslation } from 'react-i18next';

import config from '../../../config';

interface UseTimesheetAddHookType {
  loading: boolean;
  error: Error | undefined;
  createTimesheet: (data: any) => void;
}

const { api } = config;
const { baseUrl, timesheetPath } = api;

export function useTimesheetAdd(form: FormInstance<any>): UseTimesheetAddHookType {
  const { t } = useTranslation('common');
  const { post, loading, error } = useFetch(`${baseUrl}${timesheetPath}`);

  const createTimesheet = useCallback(
    async (data: any) => {
      const timesheetData = {
        description: data.taskDescription,
        from: data.timesheetFrom.format('YYYY-MM-DD'),
        to: data.timesheetFrom.format('YYYY-MM-DD'),
        projectId: data.project,
        hours: parseFloat(data.hours),
      };

      try {
        await post({
          ...timesheetData,
        });

        notification.success({
          message: 'Success',
          description: t('timesheet.saveSuccess'),
          placement: 'topLeft',
        });

        form.resetFields();
      } catch (ex) {
        notification.error({
          message: 'Error',
          description: t('timesheet.saveError'),
          placement: 'topLeft',
        });
      }
    },
    [form, post, t],
  );

  return {
    loading,
    error,
    createTimesheet,
  };
}
