import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Card, Col, Form, Row, Select } from 'antd';

import { useProjectListing } from '../contexts/ProjectListingContext';
import { useProjectList } from '../useProjectList';

import { ProjectStatus } from '../project.status';
import { ProjectType } from '../project.type';
import { SearchOutlined } from '@ant-design/icons';
import { getPageSize } from '../../../common/DataTableDefaults';

function ProjectFilters(): React.ReactElement {
  const { t } = useTranslation('common');
  const [form] = Form.useForm();

  const { projectOptions } = useProjectList();

  const { setFilterOptions, setPagination } = useProjectListing();

  const handleFilteringOptions = (): void => {
    setFilterOptions(form.getFieldsValue(['id', 'status', 'type']));
    setPagination({ page: 1, pageSize: getPageSize() });
  };

  const statusOptions = [
    {
      value: ProjectStatus.INACTIVE,
      label: 'Inactive',
    },
    {
      value: ProjectStatus.ACTIVE,
      label: 'Active',
    },
  ];

  const typeOptions = [
    {
      value: ProjectType.EXTERNAL,
      label: t('project.extern'),
    },
    {
      value: ProjectType.INTERNAL,
      label: t('project.intern'),
    },
  ];
  return (
    <Card>
      <Form
        name='filter-projects'
        form={form}
        layout='vertical'
        wrapperCol={{
          span: 20,
          offset: 0,
        }}
      >
        <Row>
          <Col xl={6} lg={6} sm={24}>
            <Form.Item label={t('project.nameLabel') ?? ''} name='id'>
              <Select options={projectOptions} allowClear showSearch></Select>
            </Form.Item>
          </Col>

          <Col xl={6} lg={6} sm={24}>
            <Form.Item label={t('project.listStatusPlaceholder')} name='status'>
              <Select defaultActiveFirstOption={true} options={statusOptions}>
                {statusOptions.map((s) => {
                  return (
                    <Select.Option key={s.value} value={s.value}>
                      {s.label}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xl={6} lg={6} sm={24}>
            <Form.Item label={t('project.listTypePlaceholder')} name='type'>
              <Select
                style={{ width: '100%' }}
                defaultActiveFirstOption={true}
                options={typeOptions}
              >
                {typeOptions.map((t) => {
                  return (
                    <Select.Option key={t.value} value={t.value}>
                      {t.label}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row justify={'end'}>
          <Col>
            <Button
              icon={<SearchOutlined />}
              onClick={() => {
                handleFilteringOptions();
              }}
            >
              {t('common.filterTitle') ?? ''}
            </Button>
            <Button
              type='text'
              onClick={() => {
                form.resetFields();
                handleFilteringOptions();
              }}
            >
              {t('common.clearBtn') ?? ''}
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default ProjectFilters;
