import React, { useMemo } from 'react';
import { Card, Col, Collapse, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'use-http';

import { useTimesheetListing } from '../contexts/TimesheetListingContext';
import { useUser } from '../../users/UserContext';

import { ProjectStatsType } from '../timesheet-types';
import { UserRole } from '../../users/user.role';
import config from '../../../../config';
import { formatMoney } from '../../../../utils/formatMoney';
import { formatNumber } from '../../../../utils/formatNumber';

const { Panel } = Collapse;

function TimesheetStatistics(): React.ReactElement {
  const { t } = useTranslation('common');
  const { roleData } = useUser();

  const { url } = useTimesheetListing();

  const statsUrl = useMemo(() => {
    const queryParams: string = url.slice(url.indexOf('?'));
    return `${config.api.baseUrl}${config.api.timesheetPath}${config.api.timesheetGetProjectStats}${queryParams}`;
  }, [url]);

  const { data: projectStats, loading: loadingStats } = useFetch<ProjectStatsType>(
    statsUrl,
    { data: {} },
    [statsUrl],
  );

  const adminStats = (
    <Spin spinning={loadingStats}>
      <Collapse>
        <Panel header={` ${t('timesheet.summary')}`} key='1'>
          <Row justify='space-between' align='middle' gutter={8}>
            <Col flex='1 0 15%'>
              <Card style={{ marginBottom: '10px', background: '#c3d1e6' }}>
                <b>Total</b>
                <div>{formatNumber(projectStats?.allProjects?.totalHours)} Hours</div>
                <div>{formatMoney(projectStats?.allProjects?.totalCost)}</div>
              </Card>
            </Col>
            {Object.entries(projectStats ?? []).map((projectArray) => {
              if (projectArray[0] === 'allProjects') {
                return <></>;
              }
              return (
                <Col flex='1 0 15%' key={projectArray[0]}>
                  <Card style={{ marginBottom: '10px' }} key={projectArray[0]}>
                    <b>{projectArray[0]}</b>
                    <div>
                      {typeof projectArray[1] === 'object'
                        ? formatNumber(projectArray[1].totalHours)
                        : ''}{' '}
                      Hours
                    </div>
                    <div>
                      {typeof projectArray[1] === 'object'
                        ? formatMoney(projectArray[1].totalCost)
                        : '0'}
                    </div>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Panel>
      </Collapse>
    </Spin>
  );

  const collaboratorStats = (
    <div>
      <b>Total hours: </b>
      {formatNumber(projectStats?.allProjects?.totalHours)}
    </div>
  );

  return <>{roleData.role === UserRole.ADMIN ? adminStats : collaboratorStats}</>;
}

export default TimesheetStatistics;
